import {FormEventHandler, useEffect} from 'react';
import Checkbox from '@/Components/Ui/Form/Checkbox';
import InputError from '@/Components/Ui/Form/InputError';
import InputLabel from '@/Components/Ui/Form/InputLabel';
import PrimaryButton from '@/Components/Ui/Buttons/PrimaryButton';
import TextInput from '@/Components/Ui/Form/TextInput';
import {Head, Link, useForm} from '@inertiajs/react';
import MemberLayout from "@/Layouts/MemberLayout";

export default function Login({status, canResetPassword, errors}: {
  status?: string,
  canResetPassword: boolean,
  errors?: any
}) {
  const {data, setData, post, processing, reset} = useForm({
    email: '',
    password: '',
    remember: false,
  });

  useEffect(() => {
    return () => {
      reset('password');
    };
  }, []);

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    post(route('login'));

  };

  return (
    <MemberLayout>
      <Head title="Log in"/>

      {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

      <form onSubmit={submit} className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12'>
        <div>
          <InputLabel htmlFor="email" value="Email"/>

          <TextInput
            id="email"
            type="email"
            name="email"
            value={data.email}
            className="mt-1 block w-full"
            autoComplete="username"
            isFocused={true}
            onChange={(e) => setData('email', e.target.value)}
          />

          <InputError message={errors.email} className="mt-2"/>
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password"/>

          <TextInput
            id="password"
            type="password"
            name="password"
            value={data.password}
            className="mt-1 block w-full"
            autoComplete="current-password"
            onChange={(e) => setData('password', e.target.value)}
          />

          <InputError message={errors.password} className="mt-2"/>
        </div>

        <div className="block mt-4">
          <label className="flex items-center">
            <Checkbox
              name="remember"
              checked={data.remember}
              onChange={(e) => setData('remember', e.target.checked)}
            />
            <span className="ms-2 text-sm text-gray-600">Remember me</span>
          </label>
        </div>

        <div className="flex items-center justify-end mt-4">
          {canResetPassword && (
            <Link
              href={route('password.request')}
              className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              Forgot your password?
            </Link>
          )}

          <PrimaryButton className="ms-4" disabled={processing}>
            Log in
          </PrimaryButton>
        </div>
      </form>
    </MemberLayout>
  );
}
